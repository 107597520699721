a {
  color: inherit;
  text-decoration: none;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: var(--background-color-paper) !important;
}

input,
textarea {
  font-size: var(--font-size);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  //border: 1px solid transparent;
  -webkit-text-fill-color: var(--text-primary);
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

#root,
div[tabindex]:not([role]):not([data-test]):not([class*="MuiPaper"]) {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 0 !important;
  width: 0px !important;
  display: none;
  background: transparent; /* Optional: just make scrollbar invisible */
}
