.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text-primary);
  font-size: 40px;
  background-image: url("./assets/bg@2x.png");
  background-repeat: no-repeat;
  background-size: cover;
}
